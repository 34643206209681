import { toast } from "react-toastify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionMeta, SingleValue } from "react-select";
import { API_CONSTANTS, VALIDATION_REGEXPS } from "../../constants";
import {
  useGetEmploymentAtListQuery,
  useGetProfessionListQuery,
} from "../../../features/api/api-slice";
import TextInput from "../../components/text-input/text-input";
import {
  EMPTY_APPLICANT_DETAILS,
  ProfessionalDetailsFormTypes,
} from "../../utils/booking-utils";
import "./applicant-form.css";
import { useRedirectToLogin, useGetUserDetailsHooks } from "../../hooks";
import Dropdown from "../../components/dropdown";

const ProfessionalDetailsForm = ({
  professionalData = {},
  onUpdate = () => {},
  isEditable = true,
}: ProfessionalDetailsFormTypes) => {
  const { t } = useTranslation();

  const [professionalDetails, setProfessionalDetails] =
    useState(professionalData);
  const professionalDetailsRef = useRef(professionalDetails);
  const [industryList, setIndustryList] = useState([]);
  const [professionList, setProfessionList] = useState([]);
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const onChangeProfession = useCallback(
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setProfessionalDetails({
        ...professionalDetailsRef.current,
        profession: newValue?.value,
      });
    },
    [setProfessionalDetails]
  );

  const onChangeProfessionInput = (newValue: string) => {
    if (newValue) {
      setProfessionalDetails({
        ...professionalDetailsRef.current,
        profession: newValue.replace(
          VALIDATION_REGEXPS.matchesAnyCharacters,
          ""
        ),
      });
    }
  };

  const onChangeIndustry = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setProfessionalDetails({
      ...professionalDetailsRef.current,
      industry: newValue?.value,
    });
  };

  const onChangeEmployedAt = (e: { target: { value: string } }) => {
    setProfessionalDetails({
      ...professionalDetailsRef.current,
      employedAt: e.target.value,
    });
  };

  const onChangeDesignation = (e: { target: { value: string } }) => {
    setProfessionalDetails({
      ...professionalDetailsRef.current,
      designation: e.target.value,
    });
  };

  const returnLabelAndValue = (list: { industry: string }[]) =>
    list?.map((item: { industry: string }) => {
      return { value: item?.industry, label: item?.industry };
    });

  const {
    data: getIndustryList,
    isSuccess: isGetIndustryListSuccess,
    isError: isGetIndustryListFailed,
  } = useGetEmploymentAtListQuery({});

  const {
    data: getProfessionList,
    isSuccess: isGetProfessionListSuccess,
    isError: isGetProfessionListFailed,
    error: getProfessionListError,
  } = useGetProfessionListQuery({ userToken: getUserToken() });

  useEffect(() => {
    if (isGetIndustryListSuccess) {
      if (
        getIndustryList?.data?.length &&
        getIndustryList?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setIndustryList(getIndustryList?.data);
      } else if (getIndustryList?.message) {
        toast(getIndustryList?.message, {
          type: "error",
        });
        setIndustryList([]);
      }
    } else if (isGetIndustryListFailed) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
      setIndustryList([]);
    }
  }, [getIndustryList, isGetIndustryListSuccess, isGetIndustryListFailed, t]);

  useEffect(() => {
    if (isGetProfessionListSuccess) {
      if (
        getProfessionList?.data?.length &&
        getProfessionList?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setProfessionList(getProfessionList?.data);
      } else if (getProfessionList?.message) {
        toast(getProfessionList?.message, {
          type: "error",
        });
        setProfessionList([]);
      }
    } else if (isGetProfessionListFailed) {
      redirectToLoginBasedOnResponse(getProfessionListError, getProfessionList);
      setProfessionList([]);
    }
    // eslint-disable-next-line
  }, [
    getProfessionList,
    isGetProfessionListSuccess,
    isGetProfessionListFailed,
    t,
    getProfessionListError,
  ]);

  const getProfessionAsLabelAndValue = useCallback(
    (list: { profession: string }[]) =>
      list?.map((item: { profession: string }) => {
        return { value: item?.profession, label: item?.profession };
      }),
    []
  );

  useEffect(() => {
    if (!Object.is(professionalDetails, EMPTY_APPLICANT_DETAILS)) {
      professionalDetailsRef.current = professionalDetails;
      onUpdate(professionalDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalDetails]);

  useEffect(() => {
    setProfessionalDetails(professionalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfession = () => (
    <Dropdown
      outerLabel={t("create_booking.professional_details.profession")}
      markRequiredField={true}
      dropdownItems={getProfessionAsLabelAndValue(professionList)}
      inputLabel={t(
        "create_booking.professional_details.profession_placeholder"
      )}
      handleChange={onChangeProfession}
      value={professionalDetails?.profession}
      isDisabled={!isEditable}
      onInputChange={onChangeProfessionInput}
    />
  );

  const renderIndustry = () => (
    <Dropdown
      outerLabel={t("create_booking.professional_details.industry")}
      markRequiredField={false}
      dropdownItems={returnLabelAndValue(industryList)}
      inputLabel={t("create_booking.professional_details.industry_placeholder")}
      handleChange={onChangeIndustry}
      value={professionalDetails?.industry}
      isDisabled={!isEditable}
    />
  );

  const renderEmployedAt = () => (
    <TextInput
      id="employed_At-input"
      value={professionalDetails?.employedAt}
      label={
        !professionalDetailsRef.current.employedAt
          ? t("create_booking.professional_details.employed_at_placeholder")
          : ""
      }
      onChange={onChangeEmployedAt}
      outerLabel={t("create_booking.professional_details.employed_at")}
      markRequiredField={false}
      disabled={!isEditable}
    />
  );

  const renderDesignation = () => (
    <TextInput
      id="designation-input"
      value={professionalDetails?.designation}
      label={
        !professionalDetailsRef.current.designation
          ? t("create_booking.professional_details.company_address_placeholder")
          : ""
      }
      onChange={onChangeDesignation}
      outerLabel={t("create_booking.professional_details.designation")}
      markRequiredField={false}
      disabled={!isEditable}
    />
  );

  return (
    <div className="single-detail-container">
      {renderProfession()}
      {renderIndustry()}
      {renderEmployedAt()}
      {renderDesignation()}
    </div>
  );
};
export default ProfessionalDetailsForm;
